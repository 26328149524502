import { Controller } from '@hotwired/stimulus'
import TomSelect from "tom-select"
import axios from 'axios'

export default class extends Controller {
  static targets = ['select']

  connect () {
    new TomSelect(this.selectTarget, {
      valueField: 'id',
      labelField: 'label',
      searchField: 'label',
      // fetch remote data
      shouldLoad: query => {
        return query.length > 2;
      },
      load: (query, callback) => {
        axios.get('/manage/navbar_search.json?query=' + query)
          .then(response => {
            callback(response.data)
          })
      },
      onChange: value => {
        var object = value.split(":");
        window.location = "/manage/" + object[0] + "/" + object[1];
      }
    })
  }
}
