import { Controller } from '@hotwired/stimulus'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid';
import $ from 'jquery'
import axios from 'axios'

export default class extends Controller {
  static targets = ["calendar"]

  connect() {
    let _this = this
    let calendar = new Calendar(this.calendarTarget, {
      events: '/manage/leaves/load_calendar_events',
      editable: false,
      selectable: false,
      navLinks: false,
      nowIndicator: true,
      initialView: 'dayGridMonth',
      plugins: [dayGridPlugin]
    })
    calendar.render()
  }

  data(info) {
    return {
      "event[start_time]": info.event.start,
      "event[end_time]": info.event.end,
    }
  }
}
