import { Controller } from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = [ 'grossAmount', 'deductionAmount', 'netAmount' ]

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : ',',
      decimalCharacter           : '.',
      decimalCharacterAlternative: ',',
      currencySymbol             : '\u202f₫',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.suffix,
      roundingMethod             : 'D',
      decimalPlaces              : 0,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.grossAmountTarget, autoNumericOptionsVND)
    new AutoNumeric(this.deductionAmountTarget, autoNumericOptionsVND)
    new AutoNumeric(this.netAmountTarget, autoNumericOptionsVND)
  }

  updateNetAmount () {
    const netAmount = this.grossAmount() - this.deductionAmount()
    AutoNumeric.getAutoNumericElement(this.netAmountTarget).set(netAmount)
  }

  updateDeductionAmount () {
    const deductionAmount = this.grossAmount() - this.netAmount()
    AutoNumeric.getAutoNumericElement(this.deductionAmountTarget).set(deductionAmount)
  }

  unformatAmountFields () {
    AutoNumeric.getAutoNumericElement(this.grossAmountTarget).formUnformat()
    AutoNumeric.getAutoNumericElement(this.deductionAmountTarget).formUnformat()
    AutoNumeric.getAutoNumericElement(this.netAmountTarget).formUnformat()
  }

  grossAmount () {
    return AutoNumeric.getAutoNumericElement(this.grossAmountTarget).getNumber()
  }

  deductionAmount () {
    return AutoNumeric.getAutoNumericElement(this.deductionAmountTarget).getNumber()
  }

  netAmount () {
    return AutoNumeric.getAutoNumericElement(this.netAmountTarget).getNumber()
  }
}
