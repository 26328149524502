import { Controller } from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = [ 'grossAmount', 'feeAmount', 'netAmount' ]

  connect () {
    const autoNumericOptionsVND = {
      digitGroupSeparator        : '.',
      decimalCharacter           : ',',
      decimalCharacterAlternative: '.',
      currencySymbol             : '$\u202f',
      currencySymbolPlacement    : AutoNumeric.options.currencySymbolPlacement.prefix,
      roundingMethod             : 'D',
      decimalPlaces              : 2,
      selectNumberOnly           : true,
      unformatOnSubmit           : true
    }

    new AutoNumeric(this.grossAmountTarget, autoNumericOptionsVND)
    new AutoNumeric(this.feeAmountTarget, autoNumericOptionsVND)
    new AutoNumeric(this.netAmountTarget, autoNumericOptionsVND)
  }

  updateNetAmount () {
    const netAmount = this.grossAmount() - this.feeAmount()
    AutoNumeric.getAutoNumericElement(this.netAmountTarget).set(netAmount)
  }

  updateGrossAmount () {
    const grossAmount = this.netAmount() - this.feeAmount()
    AutoNumeric.getAutoNumericElement(this.grossAmountTarget).set(grossAmount)
  }

  changeToPositive () {
    const grossAmount = Math.abs(this.grossAmount())
    AutoNumeric.getAutoNumericElement(this.grossAmountTarget).set(grossAmount)
    this.updateNetAmount()
  }

  changeToNegative () {
    const grossAmount = -Math.abs(this.grossAmount())
    AutoNumeric.getAutoNumericElement(this.grossAmountTarget).set(grossAmount)
    this.updateNetAmount()
  }

  grossAmount () {
    return AutoNumeric.getAutoNumericElement(this.grossAmountTarget).getNumber()
  }

  feeAmount () {
    return AutoNumeric.getAutoNumericElement(this.feeAmountTarget).getNumber()
  }

  netAmount () {
    return AutoNumeric.getAutoNumericElement(this.netAmountTarget).getNumber()
  }
}
