import { Controller } from '@hotwired/stimulus'
import PlainOverlay from 'plain-overlay'
import TomSelect from 'tom-select'
import axios from 'axios'

export default class extends Controller {
  static targets = ['select', 'overlay', 'form']

  connect () {
    new TomSelect(this.selectTarget, {
      valueField: 'id',
      labelField: 'label',
      searchField: ['id', 'label'],
      closeAfterSelect: true,
      load: (query, callback) => {
        axios.get('/manage/standard_transactions/search.json?query=' + query)
          .then(res => {
            callback(res);
          })
      },
      onChange: value => {
        document.getElementById("transaction_id").value = value;
        const e = new Event("change");
        const element = document.querySelector('#transaction_id')
        element.dispatchEvent(e);
      }
    })
  }

  createPayment() {
    this.showOverlay()
    this.formTarget.requestSubmit()
  }

  showOverlay() {
    PlainOverlay.show(this.overlayTarget)
    var overlayElement = document.querySelectorAll('.plainoverlay')[0]
    if (overlayElement !== undefined) {
      overlayElement.id = "plainoverlay"
    }
  }

}
