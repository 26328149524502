import { Controller } from '@hotwired/stimulus'
import PlainOverlay from 'plain-overlay'
import TomSelect from "tom-select"

export default class extends Controller {
  static targets = ['select', 'overlay', 'form']

  connect () {
    new TomSelect(this.selectTarget, {
      onChange: value => {
        document.getElementById("permission").value = value
        const e = new Event("change")
        const element = document.querySelector('#permission')
        element.dispatchEvent(e)
      }
    })
  }

  createPermission() {
    this.showOverlay()
    this.formTarget.requestSubmit()
  }

  showOverlay() {
    PlainOverlay.show(this.overlayTarget)
    var overlayElement = document.querySelectorAll('.plainoverlay')[0]
    if (overlayElement !== undefined) {
      overlayElement.id = "plainoverlay"
    }
  }

}
