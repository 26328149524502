import { Controller } from '@hotwired/stimulus'
import PlainOverlay from 'plain-overlay'

export default class extends Controller {
  static targets = [ 'form', 'file', 'overlay' ]

  upload() {
    if (this.fileTarget.files[0]) {
      this.showOverlay()
      this.formTarget.requestSubmit()
      this.fileTarget.value = ''
    }
  }

  selectFile() {
    this.fileTarget.click()
  }

  dragover(ev) {
    ev.preventDefault()
  }

  drop(ev) {
    ev.preventDefault()
    this.showOverlay()
    this.fileTarget.files = ev.dataTransfer.files
    this.formTarget.requestSubmit()
  }

  showOverlay() {
    PlainOverlay.show(this.overlayTarget)
    var overlayElement = document.querySelectorAll('.plainoverlay')[0]
    if (overlayElement !== undefined) {
      overlayElement.id = "plainoverlay"
    }
  }

}
