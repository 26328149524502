import { Controller } from '@hotwired/stimulus'
import PlainOverlay from 'plain-overlay'

export default class extends Controller {
  static targets = ['overlay', 'form']

  assignmentItem() {
    this.showOverlay()
    this.formTarget.requestSubmit()
  }

  showOverlay() {
    PlainOverlay.show(this.overlayTarget)

    var overlayElement = document.querySelectorAll('.plainoverlay')[0]
    if (overlayElement !== undefined) {
      overlayElement.id = "assignment-item-plainoverlay"
    }
  }

}
